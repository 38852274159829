<template>
	<transition name="slide-x-transition" mode="out-in" :duration="200">
		<component :is="type" :key="options.panelId" :options="options"></component>
	</transition>
</template>

<script>
const NotificationPanel = () => import('../notification/NotificationPanel');

export default {
	name: 'PanelWrapper',

	props: {
		options: {
			type: Object,
			required: true
		},
		type: {
			type: String,
			required: true
		}
	},

	components: {
		NotificationPanel
	}
};
</script>
